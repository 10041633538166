<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <router-link
              tag="md-button"
              class="md-just-icon md-round md-primary pull-right"
              :to="{name: 'NewUser'}"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New User</md-tooltip>
            </router-link>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getUsers"
              @onEdit="onEdit"
              @onDelete="onDelete"
              @onResendActivation="onResendActivation"
              @onLogAsUser="onLogAsUser"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';

export default {
  components: {
    VueTable,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'user_id',
            mask: 'Staff ID',
            sortable: true,
            no_format: true,
            callback: (item) => `S${(`0000${item}`).slice(-4)}`,
          },
          {
            title: 'first_name',
            mask: 'given name',
            sortable: true,
          },
          {
            title: 'last_name',
            mask: 'surname',
            sortable: true,
          },
          {
            title: 'email',
            mask: 'email',
            sortable: true,
          },
          {
            title: 'is_active',
            mask: 'Is Active',
            sortable: true,
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
          {
            title: 'is_authority',
            mask: 'authority',
            sortable: true,
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
          {
            title: 'is_default_authority',
            mask: 'default authority',
            sortable: true,
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
          {
            title: 'is_agent',
            mask: 'agent',
            sortable: true,
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
          {
            title: 'is_teacher',
            mask: 'teacher',
            sortable: true,
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
          {
            title: 'biometric_saved',
            mask: 'biometric saved',
            callback: (item) => (item !== '-' ? 'YES' : 'NO'),
          },
        ],
        actions: [
          {
            buttonClass: 'md-info',
            callback: 'onLogAsUser',
            tooltip: 'Log In as User',
            icon: 'key',
          },
          {
            buttonClass: 'md-primary',
            tooltip: 'Resend Activation Email',
            callback: 'onResendActivation',
            icon: 'email',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit User',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Deactivate User',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getUsers(params) {
      this.request(this.$API.USERS.paginableUrl(params), 'get', null, ({ data }) => {
        this.vTable.values = data;
        this.vTable.values.data = this.vTable.values.data.map((x) => {
          x.onLogAsUser = this.userIsFullAdmin;
          x.onResendActivation = !x.is_active;
          x.onDelete = x.is_active;
          return x;
        });
      });
    },
    onLogAsUser({ user_id }) {
      this.requestAsync(this.$API.LOGIN + user_id, 'post', {})
        .then((data) => {
          this.setAdminSession();
          return data;
        })
        .then((data) => {
          this.setSession(data);
          this.$router.push('/');
        });
    },
    onResendActivation(item) {
      this.fireConfirm('You are about to send an email', 'Are you sure?').then(() => {
        this.request(`${this.$API.USERS}${item.user_id}/resend_activation`, 'get', null, ({ data }) => {
          this.fireSuccess(data);
        });
      });
    },
    onEdit(item, isMiddleClick) {
      this.openLink({ name: 'EditUser', params: { id: item.user_id } }, isMiddleClick);
    },
    onDelete(item) {
      this.fireConfirm(
        'Deactivating User',
        `Are you sure you want to deactivate this User (${item.first_name} ${item.last_name})?`,
      )
        .then(() => {
          this.request(`${this.$API.USERS}${item.user_id}`, 'delete', {}, () => {
            this.fireSuccess('Student deactivated successfully.');
            // Reset Table after response
            this.$refs.vtable.init();
          });
        });
    },
  },
};
</script>

<style scoped>
</style>
